import React, { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Input, Select, Switch } from "antd";
import style from "./checkout.module.scss";
import { removeURLParam } from "./utills";
import TextArea from "antd/es/input/TextArea";
import PaypalPayment from "../subscription/SubsPayPalPayment";
import CyberSource from "./CyberSource";
import Demo_Image from "../../assets/Demo_Image_Icon.svg";
import { checkoutSettingsDefaultData, callrzpSubscription } from "./utills";
import { getWordsFromMonths } from "./utills";
import { antdMessageError } from "./utills";
import API from "../../Api/api";
import { Benifits } from "./Benifits";
import Loader from "../loader";
import { ReactComponent as DropDownArrow } from "../../assets/dropdown_arrow.svg";
import { ReactComponent as SelectArrowIcon } from "../../assets/select_arrow_icon.svg";
import { ReactComponent as CalenderIcon } from "../../assets/DateRange_Icon.svg";
import { ReactComponent as ApplePay } from "../../assets/ApplePay.svg";
import { ReactComponent as GooglePay } from "../../assets/GooglePay.svg";
import { ReactComponent as DividerIcon } from "../../assets/divider_icon.svg";
import { ReactComponent as AddPlusIcon } from "../../assets/Add_plus_icon.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/Right_arrow_icon.svg";
import Mobile_icon from "../../assets/mobile_icon.svg";
import Search_icon from "../../assets/search_icon.svg";
import AmericanExpressIcon from "../../assets/American_icon.svg";
import { Country_flag_json } from "../../consts/country-flag-json";
import dropDownStyle from "../../UI/DropDown/commonAntdSelectedBox.module.scss";
import CustomDropDownForSelect from "../../UI/DropDown/CustomDropDown";
import { AddCardDetails } from "./AddCardDetails";
import { AddAddress } from "./AddAddress";
import { SavedAddress } from "./SaveAddress";
import { ApplyCoupon } from "./ApplyCoupon";
import StepUpForm from "./StepUpForm";
import SubsStripePayment from "../subscription/SubsStripePayment";
import { Footer } from "./Footer";

const Checkout = () => {
  const [contantDetails, setContantDetails] = useState(true);
  const [showStepUp, setShowStepUp] = useState(false);
  const [stepUpUrl, setStepUrl] = useState("");
  const [userDetails, setUserDetails] = useState({
    address: {},
    billingAddress: [],
    name: "",
    phoneNumber: "",
    secondaryEmail: "",
    secondaryPhone: "",
    shippingAddress: [],
    email: "",
    dateOfBirth: "",
    gender: "",
    countryCode: "",
  } as any);
  const [subsDetailsData, setSubsDetailsData] = useState({
    benefits: "",
    convertedPrice: 0,
    currency: "",
    duration: 0,
    name: "",
    originalPrice: 0,
    proRataDiscount: 0,
    adFree: false,
    digital: false,
    physical: false,
  });
  const [checkoutSetting, setCheckoutSetting] = useState(
    checkoutSettingsDefaultData
  );
  const [loginSetting, setLoginSetting] = useState() as any;
  const [ssoRedirectUrl, setSSOredirectUrl] = useState("");
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [orderSummary, setOrderSummary] = useState(true);
  const [cyberSourcedata, setCyberSourcedata] = useState({
    accessToken: "",
    deviceDataCollectionUrl: "",
    refrenceId: "",
  });
  const [cyberSourceSessionId, setCyberSourceSessionId] = useState("");
  const [subscriptionContentDetails, setsubscriptionContentDetails] = useState(
    {}
  );
  const [cardToken, setCardToken] = useState("");
  // const [month, setMonth] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  // const [year, setYear] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [isPaypal, setisPaypal] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [renew, setRenew] = useState(false);
  const [isGift, setIsGift] = useState(false);
  const [recipientEmail, setrecipientEmail] = useState("");
  const [recipientName, setrecipientName] = useState("");
  const [recieverMessage, setRecieverMessage] = useState("");
  const [sender, setsender] = useState("");
  const [stepUpToken, setStepUpToken] = useState("");
  const [isStripe, setisStripe] = useState(false);
  useEffect(() => {
    if (stepUpToken.length > 10) {
      setShowStepUp(true);
    }
  }, [stepUpToken]);

  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState({
    name: "",
    state: "",
    country: "",
    pincode: "",
    area: "",
  });
  const [cardDetails, setCardDetails] = useState([] as any);
  const [routing, setRouting] = useState({
    color: "",
    gateway: "",
    logoUrl: "",
    publishable_key: "",
    clientName: "",
  });
  useEffect(() => {
    console.log(userDetails, "iuhdsuifs");
  }, [userDetails]);

  const [billingSameAsShipping, setbillingSameAsShipping] = useState(false);
  const [indexOfShipingAddress, setIndexOfShipingAddress] = useState(0);
  useEffect(() => {
    setAddress({ ...userDetails.shippingAddress[indexOfShipingAddress] });
  }, [indexOfShipingAddress]);
  useEffect(() => {
    if (
      typeof userDetails.shippingAddress[0] === "object" &&
      userDetails.shippingAddress[0] !== null
    ) {
      setAddress({ ...userDetails.shippingAddress[0] });
    }
  }, [userDetails]);
  const [isModalOpen, setIsModalOpen] = useState({
    addressModal: false,
    cardDetailsModal: false,
    savedAddressModal: false,
    applyCouponModal: false,
    benifits: false,
  });
  const callRoutingConfig = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const clientId = searchParams.get("clientId") || "";
    const routingResponse = await API.payment.CallRouting({
      clientId: clientId,
      // accessToken: accessToken.accessToken,
    });

    if (!API.validators.checkSuccessCode(routingResponse))
      return console.error("Unable To fetch data");

    const searchParams1 = new URLSearchParams(window.location.search);
    const paramGateway = searchParams1.get("paymentGateway");
    //hardcoding to check for cybersource
    if (
      paramGateway?.toUpperCase() === "STRIPE" ||
      paramGateway?.toUpperCase() === "RAZORPAY" ||
      paramGateway?.toUpperCase() === "PAYPAL"
    ) {
      setRouting({
        ...routingResponse?.data,
        gateway: paramGateway?.toUpperCase(),
      });
    } else {
      setRouting({ ...routingResponse?.data });
    }
    return routingResponse?.data;
  };

  const [inputValue, SetInputValue] = useState("");
  useEffect(() => {
    console.log(routing, "sdihfiosd");
  }, [routing]);
  const GenderArray = [
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Female" },
    { value: "OTHERS", label: "Others" },
  ];
  const filteredOptions = Country_flag_json.filter(
    (option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.dail_code.toLowerCase().includes(inputValue.toLowerCase())
  );
  const getCyberSourcedata = async () => {
    if (
      (checkoutSetting.deliveryAddress.customization.adsFreeSubscription
        .isRequired &&
        subsDetailsData.adFree) ||
      (checkoutSetting.deliveryAddress.customization.digitalSubscription
        .isRequired &&
        subsDetailsData.digital) ||
      (checkoutSetting.deliveryAddress.customization.physicalSubscription
        .isRequired &&
        subsDetailsData.physical)
    ) {
      if (
        !address.area ||
        !address.country ||
        !address.name ||
        !address.pincode ||
        !address.state
      ) {
        return antdMessageError("Please provide full address");
      }
    }
    if (checkoutSetting.contactInfo.nameInput.mandatory) {
      if (!userDetails.name) {
        antdMessageError("Please provide name");
        return;
      }
    }

    // let accessToken=localStorage.getItem('accessToken');

    if (userLoggedIn) {
      await API.payment.PutUserData({
        userdata: {
          ...userDetails,
          shippingAddress: address.area
            ? [...userDetails.shippingAddress, address]
            : [...userDetails.shippingAddress],
        },
        accessToken: "",
      });
    }
    const searchParams = new URLSearchParams(window.location.search);
    const clientId = searchParams.get("clientId") || "";
    const cardData = expiryDate.split("/");
    const cardExpiryMonth = cardData[0];
    const cardExpiryYear = `20${cardData[1]}`;
    // const cardNumber= ;
    // let securityCode = "";

    setbuttonLoading(true);
    try {
      const data = await API.user.CyberSourceSetUp({
        clientId,
        cardExpiryMonth,
        cardExpiryYear,
        cardNumber: cardNumber.replaceAll(/\s/g, ""),
        securityCode,
        cardTokenId: cardToken,
      });
      setCyberSourcedata({
        accessToken: data.data.data.accessToken,
        deviceDataCollectionUrl: data.data.data.deviceDataCollectionUrl,
        refrenceId: data.data.data.referenceId,
      });
    } catch (error) {
      setbuttonLoading(false);
      antdMessageError("Errror while setUp your card");
    }

    // CyberSource({accessToken:data.data.accessToken,deviceDataCollectionUrl:data.data.deviceDataCollectionUrl,refrenceId:data.data.referenceId})
  };
  const callStripe = async () => {
    if (
      (checkoutSetting.deliveryAddress.customization.adsFreeSubscription
        .isRequired &&
        subsDetailsData.adFree) ||
      (checkoutSetting.deliveryAddress.customization.digitalSubscription
        .isRequired &&
        subsDetailsData.digital) ||
      (checkoutSetting.deliveryAddress.customization.physicalSubscription
        .isRequired &&
        subsDetailsData.physical)
    ) {
      if (
        !address.area ||
        !address.country ||
        !address.name ||
        !address.pincode ||
        !address.state
      ) {
        return antdMessageError("Please provide full address");
      }
    }
    if (checkoutSetting.contactInfo.nameInput.mandatory) {
      if (!userDetails.name) {
        antdMessageError("Please provide name");
        return;
      }
    }
    let accessToken = localStorage.getItem("accessToken");

    if (userLoggedIn) {
      await API.payment.PutUserData({
        userdata: {
          ...userDetails,
          shippingAddress: address.area
            ? [...userDetails.shippingAddress, address]
            : [...userDetails.shippingAddress],
        },
        accessToken: "",
      });
    }
    const searchParams = new URLSearchParams(window.location.search);
    let succesfrontendurltemp = `${process.env.REACT_APP_SUB_URL}/thankyou?`;

    const sub = {
      brandlogoUrl: routing.logoUrl,
      successFrontendUrl: succesfrontendurltemp,
      failureFrontendUrl: `${window.location.href}`,
      subscriptionId: searchParams.get("subsId"),
      tierId: searchParams.get("tierId"),
      amount: Number(subsDetailsData.convertedPrice),
      currency: subsDetailsData.currency,
      type: "SUBSCRIPTION",
      anonId: searchParams.get("anonId"),
      pageType: searchParams.get("pageType"),
      clientId: searchParams.get("clientId"),
      clientContentId: searchParams.get("clientContentId"),
      renewSubscription: renew,
      // viewId: subscriptionPageParams?.viewId,
      userId: userDetails._id,
      eventLocation: "SRP",
      paywallId: searchParams.get("paywallId") || "",
      paywallType: searchParams.get("paywallType"),
      userToken: localStorage.getItem("refreshToken"),
      // couponCode: subscriptionPageParams.couponCode,
      // numberOfLicense: subscriptionPageParams?.numberOfLicense,
      journeyId: searchParams.get("journeyId"),
      segmentId: searchParams.get("segmentId"),
    };
    setsubscriptionContentDetails({ ...sub });
  };
  useEffect(() => {
    //@ts-ignore
    if (subscriptionContentDetails?.type === "SUBSCRIPTION") {
      console.log(subscriptionContentDetails, "fduhioujo");
      if (routing.gateway === "STRIPE") setisStripe(true);
      if (routing.gateway === "PAYPAL") setisPaypal(true);
    }
  }, [subscriptionContentDetails]);
  useEffect(() => {
    console.log(routing, "sdfsdf");
  }, [routing]);
  const CreateOrderRazorPay = async () => {
    if (
      (checkoutSetting.deliveryAddress.customization.adsFreeSubscription
        .isRequired &&
        subsDetailsData.adFree) ||
      (checkoutSetting.deliveryAddress.customization.digitalSubscription
        .isRequired &&
        subsDetailsData.digital) ||
      (checkoutSetting.deliveryAddress.customization.physicalSubscription
        .isRequired &&
        subsDetailsData.physical)
    ) {
      if (
        !address.area ||
        !address.country ||
        !address.name ||
        !address.pincode ||
        !address.state
      ) {
        return antdMessageError("Please provide full address");
      }
    }
    console.log(checkoutSetting.contactInfo, "sjdfhsiuj");
    // return
    if (checkoutSetting.contactInfo.nameInput.mandatory) {
      if (!userDetails.name) {
        antdMessageError("Please provide name");
        return;
      }
    }
    let accessToken = localStorage.getItem("accessToken");

    if (userLoggedIn) {
      await API.payment.PutUserData({
        userdata: {
          ...userDetails,
          shippingAddress: address.area
            ? [...userDetails.shippingAddress, address]
            : [...userDetails.shippingAddress],
        },
        accessToken: "",
      });
    }
    //remove it for razorpay
    let userId = "";
    // alert('dfljkhisod')
    const searchParams = new URLSearchParams(window.location.search);
    // let accessToken = localStorage.getItem("accessToken") || "";
    if (accessToken != undefined && accessToken.length > 18) {
      const arrayToken = accessToken.split(".");
      const tokenPayload = JSON.parse(atob(arrayToken[1]));
      userId = tokenPayload.sub;
    }

    // localStorage.setItem('userId',tokenPayload.sub)
    const secondStep =
      new URLSearchParams(window.location.search).get("secondStep") ||
      undefined;
    const popUpId =
      new URLSearchParams(window.location.search).get("popUpId") || undefined;
    const thirdstep =
      new URLSearchParams(window.location.search).get("thirdStep") || undefined;
    // alert(secondStep);
    // alert(thirdstep)
    // return
    const cardData = expiryDate.split("/");
    const month = cardData[0];
    const year = `20${cardData[1]}`;
    const orderObj = {
      clientId: searchParams.get("clientId"),
      SubscriptionId: searchParams.get("subsId"),
      tierId: searchParams.get("tierId"),
      type: "SUBSCRIPTION",
      anonId: searchParams.get("anonId"),
      numberOfLicense: searchParams.get("numberOfLicense"),
      userId: userId,
      secondStep,
      popUpId,
      thirdstep,
      userDetails: {
        name: userDetails.name,
        email: userDetails.email,
        phone: userDetails.phone,
        shippingAddress: address,
        // userDetails.shippingAddress[indexOfShipingAddress],
        // billingAddress:userDetails.shippingAddress[indexOfShipingAddress],
      },
      renew,
      cyberSourceSessionId,
      routing,
      cardToken,
      cardNumber: cardNumber.replaceAll(/\s/g, ""),
      month,
      year,
      subsDetailsData,
      setLoading,
    };
    setLoading(true);
    callrzpSubscription(orderObj);
    return;
    routing.gateway === "CYBERSOURCE" &&
      cyberSourceSessionId &&
      callrzpSubscription(orderObj);
  };
  const redirectToLogin = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const clientId = searchParams.get("clientId") || "";
    const url = new URL(ssoRedirectUrl);
    // y.searchParams.set('clientId',clientId)
    url.searchParams.append("redirectUrl", window.location.href);
    url.searchParams.append("clientId", clientId);
    window.location.href = url.toString();
  };
  const disableFutureAndToday = (current: any) => {
    // Get today's date
    const today = new Date();
    // Set the current date to the end of yesterday
    today.setHours(23, 59, 59, 999);
    return current && current > today;
  };
  // useEffect(()=>{
  //   console.log(checkoutSetting,subsDetailsData,'sdkjhgfiosd')
  // },[subsDetailsData,checkoutSetting])
  const cyberSourceCheckout = () => {
    // localStorage.setItem('userId',tokenPayload.sub)
    const secondStep =
      new URLSearchParams(window.location.search).get("secondStep") ||
      undefined;
    const thirdstep =
      new URLSearchParams(window.location.search).get("thirdStep") || undefined;
    const popUpId =
      new URLSearchParams(window.location.search).get("popUpId") || undefined;

    // alert(secondStep);
    // alert(thirdstep)
    // return
    const searchParams = new URLSearchParams(window.location.search);
    const cardData = expiryDate.split("/");
    const month = cardData[0];
    const year = `20${cardData[1]}`;
    const orderObj = {
      clientId: searchParams.get("clientId"),
      SubscriptionId: searchParams.get("subsId"),
      tierId: searchParams.get("tierId"),
      type: "SUBSCRIPTION",
      anonId: searchParams.get("anonId"),
      numberOfLicense: searchParams.get("numberOfLicense"),
      secondStep,
      popUpId,
      thirdstep,
      userDetails: {
        name: userDetails.name,
        email: userDetails.email,
        phone: userDetails.phone,
        shippingAddress: address,
        // userDetails.shippingAddress[indexOfShipingAddress],
        // billingAddress:userDetails.shippingAddress[indexOfShipingAddress],
      },
      renew,
      cyberSourceSessionId,
      routing,
      cardToken,
      cardNumber: cardNumber.replaceAll(/\s/g, ""),
      month,
      year,
      setLoading: setbuttonLoading,
      setShowStepUp,
      setStepUpToken,
      setStepUrl,
      setTransactionId,
    };
    callrzpSubscription(orderObj);
  };
  useEffect(() => {
    cyberSourceSessionId.length > 10 && cyberSourceCheckout();
  }, [cyberSourceSessionId]);

  const dropdownRender = (menu: React.ReactElement) => (
    <div>
      <div className={style["dropDown-search"]}>
        <Input
          placeholder="Search..."
          onChange={(e) => SetInputValue(e.target.value)}
        />
        <img src={Search_icon} alt="Search_icon" />
      </div>
      {menu}
    </div>
  );
  const handleExpiryDateChange = (e: any) => {
    let input = e.target.value;

    // Remove any non-numeric characters, except for "/"
    input = input.replace(/[^\d/]/g, "");

    // Add a "/" after the month if the user has entered two digits
    if (input.length === 2 && !input.includes("/")) {
      input = input + "/";
    }

    // Limit the input to 5 characters (MM/YY)
    if (input.length > 5) {
      input = input.slice(0, 5);
    }
    // const y=input.spl
    // console.log(input,'dfjkhiuoshjo')

    setExpiryDate(input);
  };
  const redirectToContent = async () => {
    // const accessToken = localStorage.getItem("accessToken") || "";
    // const arrayToken = accessToken.split(".");
    // const tokenPayload = JSON.parse(atob(arrayToken[1]));
    // razorPayRedirectToContent("SUBS_REDIRECT_TO_CONTENT", tokenPayload.sub);
    // if (clientContentId) {
    const searchParams = new URLSearchParams(window.location.search);
    let rid = searchParams.get("rid");
    if (rid === "null") {
      rid = null;
    }

    const redirectUrlResponse = await API.user.RedirectUserToContent({
      clientId: searchParams.get("clientId") || "",
      clientContentId: searchParams.get("clientContentId") || "",
      pageType: rid ? "AMP" : "STANDARD",
    });
    console.log(redirectUrlResponse.data, "dksjhuuiehjio");
    if (!API.validators.checkSuccessCode(redirectUrlResponse)) {
      return alert("Failed to load redirect URL");
    } else {
      const ssoUrl = redirectUrlResponse?.data?.ssoRedirectUrl;
      const redirecturl = new URL(
        decodeURIComponent(redirectUrlResponse.data?.redirectUrl)
      );

      // const x = `${ssoUrl}?origin=subsPage&clientId=${clientId}&redirectUrl=${redirecturl}&transactionId=${transactionId}`;
      const anonId = localStorage.getItem("anonId") || "";
      const paywallId = localStorage.getItem("paywallId") || "";
      // window.location.href = `${ssoUrl}?origin=subsPage&clientId=${clientId}&redirectUrl=${redirecturl}`;
      window.location.href = `${ssoUrl}?redirectUrl=${redirecturl}&clientId=${searchParams.get(
        "clientId"
      )}&anonId=${searchParams.get("anonId")}&transactionId=${searchParams.get(
        "transactionId"
      )}&contentId=${searchParams.get(
        "clientContentId"
      )}&paywallId=${searchParams.get("paywallId")}`;
    }
    // }
  };
  const getUserDetials = async () => {
    const clientId =
      new URLSearchParams(window.location.search).get("clientId") || "";
    const userData = await API.payment.userDetails({
      clientId: clientId || "",
      accessToken: "zddf",
    });
    setUserLoggedIn(true);
    setUserDetails(userData.data);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const authzCode = searchParams.get("authorizationCode");
      const client = searchParams.get("clientId");
      const rid = searchParams.get("rid");
      const couponCode = searchParams.get("couponCode") || "";
      const numberOfLicense = Number(searchParams.get("numberOfLicense"));
      const getData = await API.user.getCheckoutSettings({
        clientId: searchParams.get("clientId"),
      });
      setCheckoutSetting(getData.data.result);
      setSSOredirectUrl(getData.data.ssoRedirectUrl);

      if (authzCode) {
        const resp = await fetch(
          `${process.env.REACT_APP_API_URL_V2}/authorization/login?clientId=${client}&rid=${rid}`,
          {
            headers: {
              authorization: `Bearer ${authzCode}`,
            },
            method: "GET",
          }
        );
        const data = await resp.json();
        removeURLParam("authorizationCode");
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        // setLogged(true);
      }
      let accessToken = localStorage.getItem("accessToken");

      if (accessToken != undefined && accessToken.length > 18) {
        setTimeout(() => {
          getUserDetials();
        }, 1000);
        const queryData = new URLSearchParams(window.location.search);
        const subsId = queryData.get("subsId") || "";
        const tierId = queryData.get("tierId") || "";
        const clientId = queryData.get("clientId") || "";
        const data = await API.user.getSubsDetails({
          subsId,
          tierId,
          clientId,
          accessToken: "sdfd",
          numberOfLicense: numberOfLicense,
          couponCode,
        });
        // window.location.href=`${process.env.REACT_APP_SUB_URL}/alreadyPurchased?clientId=${clientId}&duration=${data.data.duration}&title=${data.data.name}`

        if (data.data.isPurchaseExists) {
          antdMessageError("You already have access to this subscription");

          redirectToContent();
          return;
        }
        const routingData = await callRoutingConfig();
        //hardcoding to check for cybersource
        //  routingData.gateway='CYBERSOURCE'
        if (queryData.get("amount")) {
          setSubsDetailsData({
            ...data.data,
            convertedPrice: queryData.get("amount"),
            proRataDiscount: 0,
            originalPrice: queryData.get("amount"),
            currency: queryData.get("currency"),
          });
        } else {
          setSubsDetailsData(data.data);
        }
        //  routingData.gateway='CYBERSOURCE'

        if (routingData.gateway === "CYBERSOURCE") {
          const data = await API.payment.getCardDetails({ clientId });
          setCardDetails([...data.data.cardDetails]);
        }
      } else {
        const queryData = new URLSearchParams(window.location.search);
        const subsId = queryData.get("subsId") || "";
        const tierId = queryData.get("tierId") || "";
        const clientId = queryData.get("clientId") || "";
        // const querySelector=
        const data = await API.user.getSubsDetailsAnon({
          subsId,
          tierId,
          clientId,
          numberOfLicense: numberOfLicense,
          couponCode: couponCode,
        });
        setSubsDetailsData(data.data);
        setLoading(false);
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const clientId = queryParams.get("clientId");
      const res = await API.user.getLoginSettings({ clientId });
      if (!API.validators.checkSuccessCode(res)) {
        return antdMessageError(res?.data?.message || "Failed to fetch data");
      } else {
        setLoginSetting(res.data);
      }
    })();
  }, []);
  const contactInput =
    checkoutSetting.contactInfo.DOB.isRequired ||
    checkoutSetting.contactInfo.email.isRequired ||
    checkoutSetting.contactInfo.gender.isRequired ||
    checkoutSetting.contactInfo.mobile.isRequired ||
    checkoutSetting.contactInfo.nameInput.isRequired;
  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }
  if (isStripe === true) {
    return (
      <SubsStripePayment
        subscriptionContentDetails={subscriptionContentDetails}
      />
    );
  }
  if (isPaypal === true) {
    return (
      <PaypalPayment subscriptionContentDetails={subscriptionContentDetails} />
    );
  }
  if (showStepUp === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StepUpForm
          stepUpToken={stepUpToken}
          transactionId={transactionId}
          cardToken={cardToken}
        />
      </div>
    );
  }
  return (
    <>
      {/* <div className={style['main-container']}> */}
      <ApplyCoupon setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
      <AddAddress
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        userDetails={userDetails}
        setUserDetails={setUserDetails}
      />

      <Benifits
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        benifits={subsDetailsData}
      />
      <SavedAddress
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        userDetails={userDetails}
        setIndexOfShipingAddress={setIndexOfShipingAddress}
        indexOfShipingAddress={indexOfShipingAddress}
      />
      <AddCardDetails
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />
      <div className={style["main-container"]}>
        <div className={style["header-logo"]}>
          <img
            className={style["image-logo"]}
            src={loginSetting?.loginCustomization?.brandLogoUrl}
            alt="Demo_Image"
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          <div className={style["container"]}>
            <div className={style["sub-container"]}>
              <div className={style["first-container"]}>
                {contactInput && (
                  <div className={style["box1-container"]}>
                    <div className={style["contact-details"]}>
                      <div className={style["contact-heading"]}>
                        <DropDownArrow
                          onClick={() => setContantDetails(!contantDetails)}
                          style={{
                            transform: contantDetails
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                            cursor: "pointer",
                          }}
                        />
                        <div className={style["contact-heading-text"]}>
                          Contact Details
                        </div>
                      </div>
                      {!userLoggedIn && (
                        <div className={style["already-have-account"]}>
                          Already Have an account?{" "}
                          <span
                            onClick={() => {
                              redirectToLogin();
                            }}
                            className={style["login"]}
                          >
                            Login
                          </span>
                        </div>
                      )}
                    </div>
                    {contantDetails && (
                      <>
                        {checkoutSetting.contactInfo.nameInput.isRequired && (
                          <div className={style["first-name-input"]}>
                            <Input
                              value={userDetails?.name}
                              onChange={(e) => {
                                setUserDetails({
                                  ...userDetails,
                                  name: e.target.value,
                                });
                              }}
                              placeholder="First Name & Last Name"
                            />
                          </div>
                        )}
                        <div className={style["input-text-container1"]}>
                          {checkoutSetting.contactInfo.email.isRequired && (
                            <div className={style["email-id-input"]}>
                              <Input
                                onChange={(e) => {
                                  setUserDetails({
                                    ...userDetails,
                                    email: e.target.value,
                                  });
                                }}
                                value={
                                  userDetails?.email ||
                                  userDetails?.secondaryEmail
                                }
                                placeholder="Email id"
                              />
                            </div>
                          )}
                          {checkoutSetting.contactInfo.mobile.isRequired && (
                            <div className={style["input-container"]}>
                              <img src={Mobile_icon} alt="Mobile_icon" />
                              <div className={style["dropDown-container"]}>
                                <Select
                                  value={userDetails.countryCode}
                                  placeholder={userDetails.countryCode || "+91"}
                                  dropdownRender={dropdownRender}
                                  onChange={(value) =>
                                    setUserDetails({
                                      ...userDetails,
                                      countryCode: value,
                                    })
                                  }
                                  popupMatchSelectWidth={250}
                                  filterOption={false}
                                  suffixIcon={<SelectArrowIcon />}
                                  className={dropDownStyle["common-select-box"]}
                                  popupClassName={
                                    dropDownStyle["common-dropdown-select"]
                                  }
                                  optionLabelProp="label"
                                >
                                  {filteredOptions.map((option, index) => (
                                    <Select.Option
                                      key={index}
                                      value={option.dail_code}
                                      label={option.dail_code}
                                      className={
                                        dropDownStyle["dropdown-options"]
                                      }
                                    >
                                      <div className={style["dropdown-flag"]}>
                                        <div
                                          className={style["flag-code"]}
                                          style={{ fontSize: "12px" }}
                                        >
                                          {option.dail_code}
                                        </div>
                                        <div className={style["flag-name"]}>
                                          {option.name}
                                        </div>
                                        <div className={style["flag"]}>
                                          {option.flag}
                                        </div>
                                      </div>
                                    </Select.Option>
                                  ))}
                                </Select>
                              </div>
                              <div className={style["vertical-line"]}></div>
                              <Input
                                value={
                                  userDetails.phoneNumber ||
                                  userDetails.secondaryPhone
                                }
                                onChange={(e) => {
                                  const numericValue = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  setUserDetails({
                                    ...userDetails,
                                    secondaryPhone: numericValue,
                                  });
                                }}
                                placeholder={"Mobile"}
                              />
                            </div>
                          )}
                        </div>
                        <div className={style["input-text-container2"]}>
                          {checkoutSetting.contactInfo.DOB.isRequired && (
                            <DatePicker
                              onChange={(e) => {
                                if (e) {
                                  const utcDate = new Date(
                                    Date.UTC(
                                      e.year(),
                                      e.month(),
                                      e.date(),
                                      e.hour(),
                                      e.minute(),
                                      e.second(),
                                      e.millisecond()
                                    )
                                  ).toISOString();

                                  setUserDetails({
                                    ...userDetails,
                                    dateOfBirth: utcDate,
                                  });
                                }
                              }}
                              placeholder={
                                userDetails?.dateOfBirth?.text ||
                                "Date of Birth"
                              }
                              inputReadOnly={true}
                              suffixIcon={<CalenderIcon />}
                              className={style["date-picker-input"]}
                              disabledDate={disableFutureAndToday}
                            />
                          )}
                          {checkoutSetting.contactInfo.gender.isRequired && (
                            <div className={style["gender-input"]}>
                              <CustomDropDownForSelect
                                onChangeForSelect={(e: any) =>
                                  setUserDetails({ ...userDetails, gender: e })
                                }
                                options={{ array: GenderArray, key: "value" }}
                                defaultValue={userDetails?.gender || undefined}
                                label={{ array: GenderArray, key: "label" }}
                                placeholder="Gender"
                                customStyle={{
                                  height: "40px",
                                  border: "1px solid #dedede",
                                }}
                                hoverColor="#E6E6E6"
                                dropdownStyle={{ zIndex: 1050 }}
                                // onChangeForSelect={()}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}
                {((checkoutSetting.deliveryAddress.customization
                  .adsFreeSubscription.isRequired &&
                  subsDetailsData.adFree) ||
                  (checkoutSetting.deliveryAddress.customization
                    .digitalSubscription.isRequired &&
                    subsDetailsData.digital) ||
                  (checkoutSetting.deliveryAddress.customization
                    .physicalSubscription.isRequired &&
                    subsDetailsData.physical)) && (
                  <div>
                    <div
                      className={style["contact-heading-text"]}
                      style={{ margin: "16px 0px" }}
                    >
                      Delivery Address
                    </div>

                    {typeof userDetails.shippingAddress[0] === "object" &&
                      userDetails.shippingAddress[0] !== null && (
                        <div>
                          <div className={style["address-container"]}>
                            <div className={style["address-name"]}>
                              {
                                userDetails.shippingAddress[
                                  indexOfShipingAddress
                                ].name
                              }
                            </div>
                            <div className={style["address"]}>
                              {
                                userDetails.shippingAddress[
                                  indexOfShipingAddress
                                ].area
                              }
                              {
                                userDetails.shippingAddress[
                                  indexOfShipingAddress
                                ].state
                              }
                              {
                                userDetails.shippingAddress[
                                  indexOfShipingAddress
                                ].country
                              }
                              {
                                userDetails.shippingAddress[
                                  indexOfShipingAddress
                                ].pincode
                              }
                            </div>
                          </div>
                        </div>
                      )}
                    {typeof userDetails.shippingAddress[0] === "object" &&
                      userDetails.shippingAddress[0] !== null && (
                        <div className={style["add-and-change-address"]}>
                          <div
                            className={style["add-address"]}
                            onClick={() =>
                              setIsModalOpen({
                                ...isModalOpen,
                                addressModal: true,
                              })
                            }
                          >
                            Add Address
                          </div>
                          <div
                            className={style["add-address"]}
                            onClick={() =>
                              setIsModalOpen({
                                ...isModalOpen,
                                savedAddressModal: true,
                              })
                            }
                          >
                            Change address
                          </div>
                        </div>
                      )}
                    {userDetails.shippingAddress?.length === 0 && (
                      <div style={{ marginTop: "16px" }}>
                        <div className={style["input-text-container1"]}>
                          <div className={style["pincode-input"]}>
                            <Input
                              onChange={(e) => {
                                setAddress({
                                  ...address,
                                  pincode: e.target.value,
                                });
                              }}
                              placeholder="PIN / ZIP Code"
                            />
                          </div>
                          <div className={style["pincode-input"]}>
                            <Input
                              onChange={(e) => {
                                setAddress({
                                  ...address,
                                  state: e.target.value,
                                });
                              }}
                              placeholder="State"
                            />
                          </div>
                          <div className={style["pincode-input"]}>
                            <Input
                              onChange={(e) => {
                                setAddress({
                                  ...address,
                                  country: e.target.value,
                                });
                              }}
                              placeholder="Country"
                            />
                          </div>
                        </div>
                        <div className={style["address-name-input"]}>
                          <Input
                            onChange={(e) => {
                              setAddress({ ...address, name: e.target.value });
                            }}
                            placeholder="First Name & Last Name"
                          />
                        </div>
                        <div className={style["address-name-input"]}>
                          <Input
                            onChange={(e) => {
                              setAddress({ ...address, area: e.target.value });
                            }}
                            placeholder="Full Address (House number, area... etc)"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {checkoutSetting.billingAddress && !billingSameAsShipping && (
                  <div>
                    <div
                      className={style["contact-heading-text"]}
                      style={{ margin: "16px 0px" }}
                    >
                      Billing Address
                    </div>
                    <div style={{ marginTop: "16px" }}>
                      <div className={style["input-text-container1"]}>
                        <div className={style["pincode-input"]}>
                          <Input placeholder="PIN / ZIP Code" />
                        </div>
                        <div className={style["pincode-input"]}>
                          <Input placeholder="State" />
                        </div>
                        <div className={style["pincode-input"]}>
                          <Input placeholder="Country" />
                        </div>
                      </div>
                      <div className={style["address-name-input"]}>
                        <Input placeholder="First Name & Last Name" />
                      </div>
                      <div className={style["address-name-input"]}>
                        <Input placeholder="Full Address (House number, area... etc)" />
                      </div>
                    </div>
                  </div>
                )}
                {checkoutSetting.billingAddress && (
                  <div className={style["checkbox"]}>
                    <Checkbox
                      onClick={() => {
                        setbillingSameAsShipping(!billingSameAsShipping);
                      }}
                    />
                    <div>
                      <div className={style["billing-address"]}>
                        Billing Address
                      </div>
                      <div className={style["same-as-address"]}>
                        Same as Delivery Address
                      </div>
                    </div>
                  </div>
                )}
                {/* {(checkoutSetting.giftingDetails.message.isRequired === true ||
                checkoutSetting.giftingDetails.recipientEmailPhone
                  .isRequired === true ||
                checkoutSetting.giftingDetails.recipientName.isRequired ===
                  true) && (
                <div
                  className={style["checkbox"]}
                  style={{
                    display: "block",
                    padding: "12px 12px 2px",
                    margin: "20px 0px",
                  }}
                >
                  <div className={style["flex-center"]} style={{ gap: "10px" }}>
                    <Checkbox onClick={(isGift)=>{setIsGift(!isGift)}} />
                    <div className={style["contact-heading-text"]}>
                      This is a Gift
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <div className={style["input-text-container1"]}>
                      {checkoutSetting.giftingDetails.recipientName
                        .isRequired && (
                        <div className={style["gift-input"]}>
                          <Input value={recipientName} onChange={(e)=>{setrecipientName(e.target.value)}} placeholder="Recipient’s Name" />
                        </div>
                      )}
                      {checkoutSetting.giftingDetails.recipientEmailPhone
                        .isRequired && (
                        <div className={style["gift-input"]}>
                          <Input value={recipientEmail} onChange={(e)=>{setrecipientEmail(e.target.value)}} placeholder="Recipient’s Email ID" />
                        </div>
                      )}
                    </div>
                    {checkoutSetting.giftingDetails.message.isRequired && (
                      <div className={style["address-name-input"]}>
                        <TextArea
                        value={recieverMessage} onChange={(e)=>{setRecieverMessage(e.target.value)}}
                          placeholder="Personalised message"
                          style={{ height: "80px" }}
                        />
                      </div>
                    )}
                    <div className={style["address-name-input"]}>
                      <Input 
                                              value={sender} onChange={(e)=>{setsender(e.target.value)}}

placeholder="From" />
                    </div>
                  </div>
                </div>
              )} */}
              </div>

              <div className={style["second-container"]}>
                <div style={{ flexGrow: 1 }}>
                  <div
                    className={style["order-summary"]}
                    style={{
                      backgroundColor: orderSummary ? "#ffffff" : "#f0f0f0",
                    }}
                  >
                    <div className={style["order-details"]}>
                      <div className={style["contact-heading"]}>
                        <div className={style["order-heading-text"]}>
                          Order Summary
                        </div>
                        <DropDownArrow
                          onClick={() => setOrderSummary(!orderSummary)}
                          style={{
                            transform: orderSummary
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <div className={style["order-price"]}>
                        {subsDetailsData.currency}
                        {subsDetailsData.convertedPrice}
                      </div>
                    </div>
                    {orderSummary && (
                      <>
                        <hr
                          style={{
                            border: "0.5px solid rgba(135, 135, 135, 0.1)",
                          }}
                        />
                        <div>
                          <div
                            style={{ alignItems: "start", padding: "0px 8px" }}
                          >
                            <div
                              className={style["flex-justify-space-between"]}
                            >
                              <div className={style["subs-duration"]}>
                                Duration:{" "}
                                {getWordsFromMonths(subsDetailsData.duration)}
                              </div>
                              <div
                                onClick={() => {
                                  const searchParams1 = new URLSearchParams(
                                    window.location.search
                                  );
                                  const siteUrl =
                                    searchParams1.get("siteUrl") || "";
                                  if (siteUrl.length > 10) {
                                    window.location.href = siteUrl;
                                  }
                                }}
                                className={style["change-plan"]}
                              >
                                Change Plan
                              </div>
                            </div>
                            <div className={style["subs-plan-name"]}>
                              {subsDetailsData.name}
                            </div>
                            <div
                              onClick={() => {
                                setIsModalOpen({
                                  ...isModalOpen,
                                  benifits: true,
                                });
                              }}
                              className={style["view-benefits-offers"]}
                            >
                              View all benefits & offers
                            </div>
                          </div>
                          <div
                            style={{ marginTop: "20px", padding: "0px 10px" }}
                          >
                            {/* <div className={style['flex-justify-space-between']} style={{ marginTop: '10px' }}>
                            <div className={style['apply-coupon-text']}>Apply Coupon</div>
                            <div className={style['more-coupon-text']} onClick={() => setIsModalOpen({ ...isModalOpen, applyCouponModal: true })}>More Coupons</div>
                          </div> */}
                            {/* <div className={style['flex-justify-space-between']} style={{ marginTop: '10px' }}>
                            <div className={style['apply-coupon-text']}>Have a Coupon Code?</div>
                            <div className={style['more-coupon-text']} onClick={() => setIsModalOpen({ ...isModalOpen, applyCouponModal: true })}>Apply Coupon</div>
                          </div> */}
                            {/* <div className={style['flex-justify-space-between']} style={{ marginTop: '10px' }}>
                            <div className={style['flex-center']} style={{ gap: '10px' }}>
                              <div className={style['apply-coupon-code']}>HGHGHGHG342</div>
                              <div className={style['apply-coupon-saved-rupess']}>Saved ₹500 </div>
                            </div>
                            <div className={style['remove-coupon']}>Remove</div>
                          </div> */}
                          </div>
                          <div
                            style={{ marginTop: "20px", padding: "0px 16px" }}
                          >
                            <div
                              className={style["flex-justify-space-between"]}
                              style={{ marginBottom: "10px" }}
                            >
                              <div className={style["apply-coupon-text"]}>
                                Price
                              </div>
                              <div className={style["price-amount"]}>
                                {subsDetailsData.currency}
                                {subsDetailsData.originalPrice}
                              </div>
                            </div>
                            <div
                              className={style["flex-justify-space-between"]}
                              style={{ marginBottom: "10px" }}
                            >
                              <div className={style["apply-coupon-text"]}>
                                Pro-Rata Discount
                              </div>
                              <div
                                className={style["price-amount"]}
                                style={{ color: "#008045" }}
                              >
                                -{subsDetailsData.currency}
                                {subsDetailsData.proRataDiscount}
                              </div>
                            </div>
                            {/* <div className={style['flex-justify-space-between']} style={{ marginBottom: '10px' }}>
                            <div className={style['apply-coupon-text']}>GST @10%</div>
                            <div className={style['price-amount']}>₹95.00</div>
                          </div> */}
                            <hr
                              style={{
                                border: "0.5px solid rgba(135, 135, 135, 0.1)",
                              }}
                            />
                            <div
                              className={style["flex-justify-space-between"]}
                              style={{ marginBottom: "10px" }}
                            >
                              <div className={style["price-amount"]}>Total</div>
                              <div className={style["price-amount"]}>
                                {subsDetailsData.currency}
                                {subsDetailsData.convertedPrice}
                              </div>
                            </div>
                            {/* <div className={style['flex-justify-space-between']} style={{ marginBottom: '10px' }}>
                            <div className={style['apply-coupon-text']}>Coupon Discount</div>
                            <div className={style['price-amount']} style={{ color: '#008045' }}>-₹50.00</div>
                          </div> */}
                            <hr
                              style={{
                                border: "0.5px solid rgba(135, 135, 135, 0.1)",
                              }}
                            />
                            <div
                              className={style["flex-justify-space-between"]}
                              style={{ marginBottom: "10px" }}
                            >
                              <div className={style["price-amount"]}>
                                To Pay
                              </div>
                              <div className={style["price-amount"]}>
                                {subsDetailsData.currency}
                                {subsDetailsData.convertedPrice}
                              </div>
                            </div>
                            <div
                              className={style["flex-center"]}
                              style={{ gap: "6px", marginTop: "20px" }}
                            >
                              <Switch
                                checked={renew}
                                onChange={(checked) => {
                                  setRenew(checked);
                                }}
                                size="small"
                              />
                              <div className={style["renew-text"]}>
                                Renew automatically after 1 month at{" "}
                                <span className={style["renew-price"]}>
                                  {subsDetailsData.currency}
                                  {subsDetailsData.convertedPrice}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* will do when cyber source is added */}
                  <div className={style["payment-method-container"]}>
                    {/* <div className={style['select-payment']}>Select Payment Method</div> */}
                    {/* <div className={style['payment-method']}>
                    <div className={style['apple-pay']}>
                      <ApplePay />
                    </div>
                    <div className={style['google-pay']}>
                      <GooglePay />
                    </div>
                  </div> */}
                  </div>
                  {/* <div className={style['flex-justify-center']} style={{ margin: '16px 0px 30px 0px' }}>
                  <DividerIcon />
                </div> */}
                  {routing.gateway === "CYBERSOURCE" && (
                    <>
                      {cardDetails.length >
                      (
                        <div
                          className={style["contact-heading-text"]}
                          style={{ marginBottom: "6px" }}
                        >
                          Saved Cards
                        </div>
                      )}
                      {cardDetails.map((card: any, index: number) => (
                        <div
                          onClick={() => {
                            setCardToken(card._id);
                          }}
                          style={{
                            border:
                              cardToken === card._id ? "1px solid black" : "",
                          }}
                          className={style["select-card"]}
                        >
                          <div
                            className={style["flex-center"]}
                            style={{ gap: "10px" }}
                          >
                            <div className={style["card-number"]}>
                              {card.cardDetails.number}
                            </div>
                            <div className={style["line"]}></div>
                            <div className={style["card-number"]}>
                              {card.userAccount.name}
                            </div>
                          </div>
                          <div
                            className={style["flex-center"]}
                            style={{ gap: "10px" }}
                          >
                            <div className={style["card-number"]}>
                              {card.cardDetails.cardType}
                            </div>
                            <img
                              src={AmericanExpressIcon}
                              alt="AmericanExpressIcon"
                              className={style["card-img"]}
                            />
                          </div>
                        </div>
                      ))}
                      {cardDetails.length > 0 && (
                        <div
                          className={style["add-card"]}
                          onClick={() =>
                            setIsModalOpen({
                              ...isModalOpen,
                              cardDetailsModal: true,
                            })
                          }
                        >
                          <AddPlusIcon />
                          <div>Add Card</div>
                        </div>
                      )}

                      {cardDetails.length === 0 && (
                        <div className={style["card-form-container"]}>
                          <div className={style["label-name"]}>
                            Credit / Debit Card Details
                          </div>
                          <div style={{ marginBottom: "16px" }}>
                            <div className={style["first-name-input"]}>
                              <Input placeholder="Full Name on the Card" />
                            </div>
                          </div>
                          <div style={{ marginBottom: "16px" }}>
                            <div className={style["first-name-input"]}>
                              <Input
                                value={cardNumber}
                                onChange={(e) => {
                                  const onlyNumbers = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  const formattedNumber = onlyNumbers
                                    .replace(/(.{4})/g, "$1 ")
                                    .trim();

                                  setCardNumber(formattedNumber);
                                  // setCardNumber(e.target.value);
                                }}
                                placeholder="Card Number"
                                maxLength={19}
                              />
                            </div>
                          </div>
                          <div className={style["expiration-date"]}>
                            <div style={{ width: "80%" }}>
                              <div className={style["cvv-input"]}>
                                <Input
                                  onChange={handleExpiryDateChange}
                                  placeholder="MM/YY"
                                  value={expiryDate}
                                  maxLength={5}
                                />
                              </div>
                            </div>
                            <div style={{ width: "20%" }}>
                              <div className={style["cvv-input"]}>
                                <Input
                                  value={securityCode}
                                  onChange={(e) => {
                                    setSecurityCode(e.target.value);
                                  }}
                                  placeholder="CVV"
                                  style={{ textAlign: "center" }}
                                  maxLength={3}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className={style["save-card"]}>
                          <Checkbox />
                          <div>Save card details with Razer pay gateway</div>
                        </div> */}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <Button
                  loading={buttonLoading}
                  style={{
                    background: checkoutSetting?.customizeCTA?.backgroundColor,
                    color: checkoutSetting?.customizeCTA?.color,
                    fontStyle: checkoutSetting?.customizeCTA?.fontStyle,
                    fontWeight: checkoutSetting?.customizeCTA?.fontWeight,
                  }}
                  onClick={() => {
                    routing.gateway === "RAZORPAY" && CreateOrderRazorPay();
                    routing.gateway === "CYBERSOURCE" && getCyberSourcedata();
                    (routing.gateway === "STRIPE" ||
                      routing.gateway === "PAYPAL") &&
                      callStripe();
                    //  routing.gateway==='PAYPAL' && setisPaypal(true)
                  }}
                  className={style["pay-btn"]}
                >
                  <div className={style["flex-center"]} style={{ gap: "10px" }}>
                    <div className={style["pay-btn-text"]}>
                      {subsDetailsData.currency}
                      {subsDetailsData.convertedPrice}
                    </div>
                    {/* <div className={style['strick-price']}>₹1100</div> */}
                  </div>
                  <div className={style["flex-center"]} style={{ gap: "10px" }}>
                    <div className={style["pay-btn-text"]}>
                      {checkoutSetting?.customizeCTA?.text || "Pay"}
                    </div>
                    <RightArrowIcon />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Footer
          termsAndConditions={loginSetting?.loginCustomization?.redirects?.tnc}
          privacyAndPolicy={
            loginSetting?.loginCustomization?.redirects?.privPolicy
          }
        />
      </div>
      {cyberSourcedata?.accessToken?.length > 10 && (
        <CyberSource
          cyberSourcedata={cyberSourcedata}
          setCyberSourceSessionId={setCyberSourceSessionId}
        />
      )}
    </>
  );
};

export default Checkout;
