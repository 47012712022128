import React, { useState } from 'react'
import style from './checkout.module.scss'
import CommonModal from '../../UI/CommonModal/CommonModal'
import { Checkbox, Input } from 'antd'
import API from '../../Api/api'

export const AddCardDetails = ({
  setIsModalOpen,
  isModalOpen
}: {
  setIsModalOpen: any;
  isModalOpen: any
}) => {
  const [card,setcard]=useState({cardExpiryMonth:'',cardExpiryYear:'',cardNumber:'',cardType:'',name:''})
  async function savecardDetails() {
    const clientId = new URLSearchParams(window.location.search).get("clientId") || ''

  const data=await API.payment.addCardDetails({clientId,cardExpiryMonth:card.cardExpiryMonth,cardExpiryYear:card.cardExpiryYear,cardNumber:card.cardNumber,cardType:card.cardType})
    
  }
  return (
    <CommonModal
      isModalVisible={() => setIsModalOpen({ ...isModalOpen, cardDetailsModal: false })}
      visible={isModalOpen?.cardDetailsModal}
      centered
    >
      <div style={{ padding: '20px' }}>
        <div className={style['add-card-heading']}>Add Card Details</div>
        <div className={style['card-form-container']}>
          <div style={{ marginBottom: '16px' }}>
            <div className={style['label-name']} >Full Name</div>
            <div className={style['first-name-input']}>
              <Input onChange={(e)=>{setcard({...card,name:e.target.value})}} placeholder='Name on the Card' />
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className={style['label-name']}>Card Number</div>
            <div className={style['first-name-input']}>
              <Input onChange={(e)=>{setcard({...card,cardNumber:e.target.value})}} placeholder='1234 5678 9101 1121' />
            </div>
          </div>
          <div className={style['expiration-date']}>
            <div style={{ width: '80%' }}>
              <div className={style['label-name']}>Expiration Date</div>
              <div className={style['cvv-input']}>
                <Input onChange={(e)=>{setcard({...card,cardExpiryMonth:'12',cardExpiryYear:'2024'})}} placeholder='MM/YY' />
              </div>
            </div>
            <div style={{ width: '20%' }}>
              <div className={style['label-name']}>CVV</div>
              <div className={style['cvv-input']}>
                <Input onChange={(e)=>{setcard({...card,cardType:e.target.value})}} placeholder='123' style={{ textAlign: 'center' }} />
              </div>
            </div>
          </div>
          <div className={style['save-card']}>
            <Checkbox />
            <div>Save card details with CyberSource</div>
          </div>
        </div>
        <div className={style['button-container']}>
          <button className={style['discard-btn']}>Discard</button>
          <button  onClick={()=>{savecardDetails()}} className={style['save-btn']}>Save</button>
        </div>
      </div>
    </CommonModal>
  )
}