import React, { useEffect, useState } from 'react'
import style from './checkout.module.scss'
import CommonModal from '../../UI/CommonModal/CommonModal'
import { Input } from 'antd'
import API from '../../Api/api'
import { antdMessageError } from './utills'
export const AddAddress = ({
  setIsModalOpen,
  isModalOpen,
  userDetails,
  setUserDetails
}: {
  setIsModalOpen: any;
  isModalOpen: any;
  userDetails:any;
  setUserDetails:any
}) => {
  const[address,setAddress]=useState({name:'',state:'',country:'',pincode:'',area:''});
  const saveData=async()=>{

    if(!address.state){
      antdMessageError('Please provide State')
      return
    }
    if(!address.pincode){
      antdMessageError('Please provide Pincode')
      return
    }
  if(!address.country){
    return antdMessageError('Please provide Country')
  } 
  if(!address.area){
    return antdMessageError('Please provide Area')
  }
  let tempuser=userDetails
  tempuser.shippingAddress=[...tempuser?.shippingAddress,address]
 const data=await API.payment.PutUserData({userdata:tempuser,accessToken:localStorage.getItem('accessToken')||''})
    // console.log(tempuser,'dsfkhiousdfjoi')

    // alert('save address')
  }
  const discardData=()=>{
    setAddress({name:'',state:'',country:'',pincode:'',area:''})
    setIsModalOpen({ ...isModalOpen, addressModal: false })
  }

  return (
    <CommonModal
      isModalVisible={() => setIsModalOpen({ ...isModalOpen, addressModal: false })}
      visible={isModalOpen?.addressModal}
      centered
    >
      <div style={{ padding: '20px' }}>
        <div className={style['add-card-heading']}>Add New Address</div>
        <div style={{ marginTop: '16px' }}>
          <div className={style['input-text-container1']}>
            <div className={style['pincode-input']}>
              <Input value={address.pincode||''} onChange={(e)=>{setAddress({...address,pincode:e.target.value})}} placeholder='PIN / ZIP Code' />
            </div>
            <div className={style['pincode-input']}>
              <Input value={address.state||''} onChange={(e)=>{setAddress({...address,state:e.target.value})}} placeholder='State' />
            </div>
            <div className={style['pincode-input']}>
              <Input value={address.country||''} onChange={(e)=>{setAddress({...address,country:e.target.value})}} placeholder='Country' />
            </div>
          </div>
          <div className={style['address-name-input']}>
            <Input value={address.name||''} onChange={(e)=>{setAddress({...address,name:e.target.value})}} placeholder='First Name & Last Name' />
          </div>
          <div className={style['address-name-input']}>
            <Input value={address.area||''} onChange={(e)=>{setAddress({...address,area:e.target.value})}} placeholder='Full Address (House number, area... etc)' />
          </div>
        </div>

        <div className={style['button-container']}>
          <button onClick={()=>{discardData()}} className={style['discard-btn']}>Discard</button>
          <button onClick={()=>{saveData()}} className={style['save-btn']}>Save</button>
        </div>
      </div>
    </CommonModal>
  )
}